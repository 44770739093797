/* eslint-disable no-use-before-define */
/* eslint-disable no-promise-executor-return */
import { createSlice } from '@reduxjs/toolkit';
import {
  getDuplicateContacts,
  getAgentsChat,
  getWhatsappQrCode,
  setMessengerUserDataAsync,
  messengerManager,
  getContact,
  getLeadsStatus,
  changeLeadStatus,
  getPropertiesToShare,
} from './services';

// Initial state
const initialState = {
  rightbar: false,
  data: null,
  show: false,
  channelData: false,
  whatsapp: {
    loading: false,
    qr: false,
    conected: false,
    showQRModal: false,
    showWhatsappAd: true,
  },
  agents: {
    loading: false,
    asingLoader: false,
    list: [],
    agentSelected: [],
  },
  duplicateContacts: {
    loading: false,
    contacts: [],
    total: 0,
  },
  filters: {
    id: '',
    config: {},
  },
  permissions: {
    canSeeWebContacts: false,
    canChangeContactAgent: false,
  },
  contacts: [],
  leadsStatusList: [],
  shareProperties: {
    properties: [],
    pageInfo: false,
    loading: false,
    selectedProperty: false,
    show: false,
  },
};

// Actual Slice
export const MessengerSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setChannelData(state, action) {
      const { _data, listeners, ...filterData } = action.payload;

      state.channelData = filterData;
    },

    changeLeadToContact(state, action) {
      const { id, status } = action.payload;

      const existingIndex = state.contacts.findIndex(
        (contact) => contact?.id === id
      );

      state.contacts[existingIndex].status = status;
    },

    setFilter(state, action) {
      const MEMBER = { $in: [state.data.info.id] };
      const ALLTYPES = {
        $in: ['team', 'Colleague', 'feedback', 'WhatsAppContact'],
      };

      if (state.permissions.canSeeWebContacts) {
        ALLTYPES.$in.push('WhatsAppGuest');
      }

      const CONFIGS = {
        all: {
          type: ALLTYPES,
          members: MEMBER,
        },

        assigned: {
          $or: [
            {
              type: { $in: ['WhatsAppContact', 'Colleague'] },
              members: MEMBER,
            },
            {
              type: 'WhatsAppGuest',
              frozen: true,
              members: MEMBER,
            },
          ],
        },

        unreads: {
          type: ALLTYPES,
          members: MEMBER,
          has_unread: true,
          frozen: false,
        },

        active: { type: ALLTYPES, members: MEMBER, frozen: false },

        unassigned: {
          type: {
            $in: ['WhatsAppGuest'],
          },
          members: MEMBER,
          frozen: false,
        },
      };

      state.filters.id = action.payload;
      state.filters.config = CONFIGS[action.payload];
    },

    resetDuplicated(state) {
      Object.assign(state.duplicateContacts, initialState.duplicateContacts);
    },

    showRightbar(state, action) {
      state.rightbar = action.payload;
    },

    setSelectedAgent(state, action) {
      const { agentSelected } = state.agents;

      const hasAgent = agentSelected.find(
        (agent) => agent.id === action.payload?.id
      );

      if (hasAgent) {
        state.agents.agentSelected = agentSelected.filter(
          (agent) => agent.id !== action.payload.id
        );
      } else {
        state.agents.agentSelected = action.payload ? [action.payload] : [];
      }
    },

    setPermissions(state, action) {
      state.permissions = action.payload;
    },

    showTokkoChat(state, action) {
      state.show = action.payload;

      if (!action.payload) {
        Object.assign(state, {
          ...initialState,
          data: state.data,
          filters: state.filters,
        });
      }
    },

    setShowModalWhatsapp(state, action) {
      const { value, key } = action.payload;

      state.whatsapp[key] = value;
    },

    sharePropertiesChangeValues(state, action) {
      const { key, value } = action.payload;

      state.shareProperties[key] = value;

      if (key === 'show' && !value) {
        Object.assign(state.shareProperties, initialState.shareProperties);
      }
    },
  },

  extraReducers: (builder) => {
    builder.addCase(setMessengerUserDataAsync.fulfilled, (state, action) => {
      state.data = action.payload;

      MessengerSlice.caseReducers.setFilter(state, {
        type: 'chat/setFilter',
        payload: 'active',
      });
    });

    builder
      .addCase(getWhatsappQrCode.pending, (state) => {
        state.whatsapp.loading = true;
      })
      .addCase(getWhatsappQrCode.fulfilled, (state, action) => {
        state.whatsapp.loading = false;
        state.whatsapp.conected = true;
        state.whatsapp.qr = action.payload.qrcode;
      });

    builder // get agents
      .addCase(getAgentsChat.pending, (state) => {
        state.agents.loading = true;
      })
      .addCase(getAgentsChat.fulfilled, (state, action) => {
        state.agents.loading = false;
        state.agents.list = action.payload.users;
      });

    builder // get duplicate contacts
      .addCase(getDuplicateContacts.pending, (state) => {
        state.duplicateContacts.loading = true;
      })
      .addCase(getDuplicateContacts.fulfilled, (state, action) => {
        state.duplicateContacts = { ...action.payload, loading: false };
      });

    builder // messenger Manager
      .addCase(messengerManager.pending, (state) => {
        state.agents.asingLoader = true;
      })
      .addCase(messengerManager.fulfilled, (state) => {
        state.agents.asingLoader = false;
      });

    builder // get contacts
      .addCase(getContact.fulfilled, (state, action) => {
        const newContact = action.payload.contacts[0];

        const existingIndex = state.contacts.findIndex(
          (contact) => contact?.id === newContact?.id
        );

        if (existingIndex !== -1) {
          state.contacts[existingIndex] = newContact;
        } else {
          state.contacts.push(newContact);
        }
      });

    builder // get leads status
      .addCase(getLeadsStatus.fulfilled, (state, action) => {
        state.leadsStatusList = action.payload;
      });

    builder // change lead status
      .addCase(changeLeadStatus.fulfilled, (state) => {
        state.loading = false;
      });

    builder // get properties to share
      .addCase(getPropertiesToShare.pending, (state) => {
        state.shareProperties.loading = true;
      })
      .addCase(getPropertiesToShare.fulfilled, (state, action) => {
        const { properties } = state.shareProperties;

        state.shareProperties.loading = false;
        state.shareProperties.pageInfo = action.payload.page_info;

        const newProperties = action.payload.properties.filter(
          (newProperty) =>
            !properties.some(
              (existingProperty) => existingProperty.id === newProperty.id
            )
        );

        state.shareProperties.properties = [...properties, ...newProperties];
      });
  },
});

export const {
  setChannelData,
  showTokkoChat,
  setShowQRModal,
  setShowWhatsappAd,
  setShowModalWhatsapp,
  setSelectedAgent,
  showRightbar,
  resetDuplicated,
  setFilter,
  setPermissions,
  changeLeadToContact,
  sharePropertiesChangeValues,
} = MessengerSlice.actions;

export default MessengerSlice.reducer;
